<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      CSTR
                    </h5>
                    <p class="text-sm text-body mb-0"></p>
                  </div>
                </div>

                <v-card class="border-radius-xl mt-6">
                  <v-card-text class="px-3 py-0">
                    <v-row class="m-0">
                      <v-col cols="12" md="4">
                        <v-menu ref="mnu_frm_date" v-model="mnu_frm_date" :close-on-content-click="false"
                          :return-value.sync="mnu_frm_date" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="frm_date" readonly v-bind="attrs" v-on="on" hide-details="auto" class="
                                                                                          input-style
                                                                                          font-size-input
                                                                                          text-light-input
                                                                                          placeholder-light
                                                                                          input-icon                      
                                                                                        " dense flat filled solo
                              height="43" placeholder="Date" persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="frm_date" no-title
                            scrollable :max="to_date">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="mnu_frm_date = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.mnu_frm_date.save(frm_date)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu ref="mnu_to_date" v-model="mnu_to_date" :close-on-content-click="false"
                          :return-value.sync="mnu_to_date" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="to_date" readonly v-bind="attrs" v-on="on" hide-details="auto" class="
                                                                                          input-style
                                                                                          font-size-input
                                                                                          text-light-input
                                                                                          placeholder-light
                                                                                          input-icon                     
                                                                                        " dense flat filled solo
                              height="43" placeholder="Date" persistent-hint></v-text-field>
                          </template>
                          <v-date-picker color="green lighten-1" header-color="primary" v-model="to_date" no-title
                            scrollable :min="frm_date">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="mnu_to_date = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.mnu_to_date.save(to_date)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4" align-self="center">
                        <v-btn color="primary" elevation="0" :ripple="false" height="43" dark
                          class="text-capitalize btn-ls btn-primary bg-success py-3 px-6" @click="search">
                          Search
                        </v-btn></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>

            <v-card class="card-shadow border-radius-xl mt-6" v-if="blendPlans.length > 0">
              <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="blendPlans">
                  <template v-slot:[`item.productionDate`]="{ item }">
                    <span>
                      {{ formatDate(item.productionDate) }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip label small :ripple="false" :color="planStatus(item).color">
                      {{ planStatus(item).label }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <!--      -->
                    <v-btn v-if="item.viewstatus == 'Add'" @click="upload(item)" icon elevation="0" :ripple="false"
                      height="28" min-width="36" width="36" class="" color="green">Add
                    </v-btn>
                    <!--     -->
                    <v-btn v-if="item.viewstatus == 'View'" @click="view(item)" icon elevation="0" :ripple="false"
                      height="28" min-width="36" width="36" class="" color="green">View
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <!-- File Upload -->
      <v-dialog v-model="dialog" persistent>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-form ref="frm">
              <v-container><v-row class="pa-4 ms-3">
                  <v-col cols="2">
                    <v-row class="tittle__row">
                      <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Name</label>
                    </v-row>
                    <v-row>
                      <label class="text-lg text-typo ms-1 label__value mt-2">{{
                        selectedItem.name
                      }}</label>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row class="tittle__row">
                      <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Blend</label>
                    </v-row>
                    <v-row>
                      <label class="text-lg text-typo ms-1 label__value mt-2">{{
                        selectedItem.blendCodeName
                      }}</label>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="tittle__row">
                      <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Qty</label>
                    </v-row>
                    <v-row>
                      <label class="text-lg text-typo ms-1 label__value mt-2">{{
                        selectedItem.productionQuantity
                      }}</label>
                    </v-row> </v-col><v-col>
                    <v-row class="tittle__row">
                      <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Production Date</label>
                    </v-row>
                    <v-row>
                      <label class="text-lg text-typo ms-1 label__value mt-2">
                        {{ formatDate(selectedItem.productionDate) }}
                      </label>
                    </v-row> </v-col><v-col>
                    <v-row class="tittle__row">
                      <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Warehouse</label>
                    </v-row>
                    <v-row>
                      <label class="text-lg text-typo ms-1 label__value">{{
                        selectedItem.companyWarehouseName
                      }}</label>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                        </strong></span>
                      File</label>

                    <v-file-input prepend-inner-icon v-model="file" hide-details="auto" class="
                                                                                                        input-style
                                                                                                        font-size-input
                                                                                                        text-light-input
                                                                                                        placeholder-light
                                                                                                        input-icon
                                                                                                        mt-5
                                                                                                      " dense flat filled
                      solo height="46" placeholder="Upload CSTR"
                      :rules="[(v) => !!v || 'CSTR is required']"></v-file-input>
                  </v-col>
                  <v-col><label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                        </strong></span>
                      Status</label>
                    <v-select v-model="status" :items="blend_status" item-text="status" item-value="value"
                      color="rgba(0,0,0,.6)" class="
                                                                                                        input-style
                                                                                                        font-size-input
                                                                                                        text-light-input
                                                                                                        placeholder-light
                                                                                                        border-radius-md
                                                                                                        select-style
                                                                                                        mb-0
                                                                                                        mt-5
                                                                                                      "
                      placeholder="Select a Status" :rules="[(v) => !!v || 'Status is Required']" outlined single-line
                      height="46">
                    </v-select>
                  </v-col>
                  <v-col><v-btn @click="save" elevation="0" :ripple="false" height="46"
                      class="
                                                                                                        font-weight-bold
                                                                                                        text-capitalize
                                                                                                        btn-ls btn-primary
                                                                                                        bg-success
                                                                                                        py-3
                                                                                                        px-6
                                                                                                        mt-10
                                                                                                        mr-3
                                                                                                      ">Save</v-btn><v-btn @click="cancel" elevation="0" :ripple="false" height="46"
                      class="
                                                                                                        font-weight-bold
                                                                                                        text-capitalize
                                                                                                        btn-ls btn-secondary
                                                                                                        bg-light
                                                                                                        py-3
                                                                                                        px-6
                                                                                                        mt-10
                                                                                                      ">cancel</v-btn></v-col>
                  <v-col></v-col>
                </v-row> </v-container></v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- View Upload -->
      <v-dialog v-model="dialogView" persistent>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-container><v-row class="pa-4 ms-3">
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Name</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value mt-2">{{
                      selectedItem.name
                    }}</label>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Blend</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value mt-2">{{
                      selectedItem.blendCodeName
                    }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Qty</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value mt-2">{{
                      selectedItem.productionQuantity
                    }}</label>
                  </v-row> </v-col><v-col>
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Production Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value mt-2">
                      {{ formatDate(selectedItem.productionDate) }}
                    </label>
                  </v-row> </v-col><v-col>
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Warehouse</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value mt-2">{{
                      selectedItem.companyWarehouseName
                    }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md font-weight-bolder ms-1 mandatory ml-2">Status</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      selectedItem.cstrStatus
                    }}</label>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset-sm="8"><v-btn v-if="selectedItem.url" @click="download" elevation="0" :ripple="false"
                    height="46" class="
                                          font-weight-bold
                                          text-capitalize
                                          btn-ls btn-primary
                                          bg-success
                                          py-3
                                          px-6
                                          mt-10
                                          mr-3
                                        ">Download</v-btn>
                  <v-btn @click="cancel" elevation="0" :ripple="false" height="46" class="
                                          font-weight-bold
                                          text-capitalize
                                          btn-ls btn-secondary
                                          bg-light
                                          py-3
                                          px-6
                                          mt-10
                                        ">cancel</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import blendApi from "../../blendSheet/api";
import api from "../api";
import { AWS_URL } from "../../../../config";
export default {
  async mounted() {
    await this.init();
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      dialogView: false,
      blendPlans: [],
      Cstr: [],
      selectedItem: {
        id: 0,
        status: "",
        name: "",
        blendCodeName: "",
        productionDate: "",
      },
      blend_status: [
        {
          status: "Approve",
          value: "Approved",
        },
        {
          status: "Reject",
          value: "Rejected",
        },
      ],
      headers: [
        { text: "Name", width: "150", value: "name" },
        { text: "Blend", width: "150", value: "blendCodeName" },
        { text: "Blend Type", width: "150", value: "blendTypeName" },
        { text: "Quantity", width: "100", value: "productionQuantity" },
        { text: "Production Date", width: "150", value: "productionDate" },
        { text: "Warehouse", width: "150", value: "companyWarehouseName" },
        // { text: "Generated", width: "100", value: "planGenerationStatus" },
        { text: "Status", width: "50", value: "status" },
        { text: "Actions", width: "50", value: "actions" },
      ],

      file: "",

      frm_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_frm_date: false,

      to_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_to_date: false,

      status: "",
    };
  },
  methods: {
    async init() {
      this.blendPlans = [];
    },
    async search() {
      this.overlay = true;
      this.blendPlans = [];
      let blendPlans = await blendApi.getApprovedPlans(this.frm_date, this.to_date);
      this.Cstr = await api.getCstr();
      console.log("this.blendPlans", this.blendPlans);

      for await (const item of blendPlans) {
        let idx = this.Cstr.findIndex((el) => el.blend_sheet_id == item.id);

        if (idx > -1) {
          item.viewstatus = "View";
          item.cstrID = this.Cstr[idx].id;
          item.cstrStatus = this.Cstr[idx].status;
          item.url = this.Cstr[idx].url;
        } else {
          item.viewstatus = "Add";
        }
        this.blendPlans.push(item);
      }

      if (this.blendPlans.length == 0) {
        this.showNoDataAlert("No Data Found")
      }
      // console.log(" this.blendPlans", this.blendPlans);
      this.overlay = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    planStatus(item) {
      if (!item.approvalStatus && !item.cancellationStatus) {
        return { label: "Pending", color: "rgba(255, 110, 64, 0.5)" };
      }
      if (item.approvalStatus)
        return { label: "Approved", color: "rgba(105, 240, 174, 0.5)" };
      if (item.cancellationStatus)
        return { label: "Cancelled", color: "rgba(237, 63, 59, 0.5)" };
    },
    upload(item) {
      this.selectedItem = item;

      this.dialog = true;
    },
    view(item) {
      this.selectedItem = item;
      console.log("item", item);
      this.dialogView = true;
    },
    download() {
      const link = document.createElement("a");
      link.href = AWS_URL + this.selectedItem.url;
      let url = this.selectedItem.url + "";
      let ext = url.substring(url.lastIndexOf("."));
      link.setAttribute("download", `CSTR-Download${ext}`);
      document.body.appendChild(link);
      link.click();
    },
    cancel() {
      this.selectedItem = {
        id: 0,
        status: "",
        name: "",
        blendCodeName: "",
        productionDate: "",
      };
      this.file = "";

      this.status = "";
      this.dialog = false;
      this.dialogView = false;
      this.$refs.frm.resetValidation();
    },
    validate() {
      return this.$refs.frm.validate();
    },
    async save() {
      if (this.validate()) {
        this.overlay = true;
        this.dialog = false;
        let bodyFormData = new FormData();
        bodyFormData.append("file", this.file, this.file.name);
        bodyFormData.append("id", this.selectedItem.id);
        bodyFormData.append("status", this.status);
        await api.uploadFile(bodyFormData);
        await this.init();
        this.cancel();
        this.showSuccessAlert("File Uploaded");
        this.overlay = false;
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
