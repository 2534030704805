import axios from "axios";

export default {
  uploadFile(item) {
    return axios
      .post("blend/cstr-upload", item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getCstr() {
    return await axios
      .get("blend/cstr")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async download(url) {
    let u = "https://purchase-and-blend-docs.s3.ap-south-1.amazonaws.com";
    const link = document.createElement("a");
    link.href = u + url;
    link.setAttribute("download", `Purchase-Summary.xlsx`);
    document.body.appendChild(link);
    link.click();
  },
};
